<template>
  <div id="app">
    <div class="spinner-wrapper" v-if="showSpinner">
      <Spinner
        class="dm-spinner"
        size="large"
        line-fg-color="#ff0000"
        message="Učitavam"></Spinner>
    </div>
    <div class="top-header">
      <div class="top-header-left"><img src="./assets/DM-Logo.png"></div>
      <div class="top-header-right">
        <information-box :boxData="contactDetails"></information-box>
        <information-box :boxData="workingHours"></information-box>
        <information-box :boxData="address"></information-box>
      </div>
    </div>
    <Slide class="burger-slider"
        right
        :closeOnNavigation="true">
      <router-link to="/" tag="button">POČETNA</router-link>
      <router-link to="/ponuda-vozila" tag="button">PONUDA VOZILA</router-link>
      <router-link to="/ponuda-prikolica" tag="button">PONUDA PRIKOLICA</router-link>
      <router-link to="/kontakt" tag="button">KONTAKT</router-link>
    </Slide>
    <div class="nav-wrapper">
      <div id="nav">
        <router-link to="/" tag="button">POČETNA</router-link>
        <router-link to="/ponuda-vozila" tag="button">PONUDA VOZILA</router-link>
        <router-link to="/ponuda-prikolica" tag="button">PONUDA PRIKOLICA</router-link>
        <router-link to="/kontakt" tag="button">KONTAKT</router-link>
      </div>
    </div>
    <router-view/>
    <div class="footer">
      <div class="footer-info">
        Auto Salon DM Automobili d.o.o | Buna bb, 88000 Mostar
      </div>
      <div class="footer-info">
        Telefon: +387 63 754 800 | +387 63 754 777
      </div>
      <div class="footer-info">
        © Copyright - DM Automobili d.o.o.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import { Slide } from 'vue-burger-menu';
import Spinner from 'vue-simple-spinner';
import InformationBox from './components/InfoBox.vue';

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'contactDetails',
      'workingHours',
      'address',
      'getCarOffer',
      'getTotalArticles',
      'getCarOfferTotalPages',
    ]),
  },
  components: {
    InformationBox,
    Slide,
    Spinner,
  },
  data() {
    return {
      offerPagination: [],
      showSpinner: false,
      label: 'Učitavam',
    };
  },
  methods: {
    ...mapMutations([
      'setCarOffer',
      'setTotalArticles',
      'setCarOfferTotal',
    ]),
    getArticlePage(page) {
      return page;
    },
  },
  mounted() {
    this.showSpinner = true;
    axios.get(
      'https://api.jsonstorage.net/v1/json/f6a3dd56-a069-4d5e-809a-d5d1600a3f2c/2eb5663b-04ac-4b41-af42-9d755d3f0cdb',
    ).then((response) => {
      console.log('Response:', response);
      this.setCarOffer(response.data.data[0]);
      this.setTotalArticles(response.data.data[0].total);
      this.setCarOfferTotal(response.data.data);
    }).then(() => {
      this.showSpinner = false;
    });
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans&display=swap');

body, html {
  margin: 0;
  background-color: #ebebeb;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .spinner-wrapper {
    position: fixed;
    background-color: rgba(white, .7);
    width: 100%;
    height: 100%;
    .dm-spinner {
      position: fixed;
      top: 50%;
      left: 50%;
      font-weight: bold;
      color: #000000;
    }
  }
  .top-header {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 130px;
    align-items: center;
    background-color: #ffffff;
    img {
      height: 35px;
    }
    .top-header-left {
      padding-right: 100px;
    }
    .top-header-right {
      display: flex;
      font-family: 'Montserrat', sans-serif;
    }
  }
  .footer {
      margin-top: 20px;
      background-color: #212121;
      height: 60px;
      padding: 30px 0px;
      .footer-info {
        font-family: 'Open Sans', sans-serif;
        font-size: 11px;
        color: #999999;
        padding-left: 10px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
      }
    }
}
.nav-wrapper {
  width: 100%;
  background-color: #000000;
  #nav {
    max-width: 1200px;
    margin: 0 auto;
    height: 55px;
    background-color: #000000;
    display: flex;

    button {
      border: none;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      background-color: #000000;
      color: #ffffff;
      padding: 0px 20px;
      transition: ease-in-out .5s;
      &:hover {
        background-color: #D33B2B;
        cursor: pointer;
      }
    }
    .router-link-exact-active {
      background-color: #D33B2B;
    }
    .empty {
      width: 200px;
    }
  }
}
.burger-slider {
  display: none;
}
.bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
      cursor: pointer;
    }
    .bm-burger-bars {
      background-color: #000000 !important;
    }
    .line-style {
      position: absolute;
      height: 20%;
      left: 0;
      right: 0;
    }
    .cross-style {
      position: absolute;
      top: 12px;
      right: 2px;
      cursor: pointer;
    }
    .bm-cross {
      background: #bdc3c7;
    }
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
    .bm-menu {
      height: 100%; /* 100% Full-height */
      width: 0; /* 0 width - change this with JavaScript */
      position: fixed; /* Stay in place */
      z-index: 1000; /* Stay on top */
      top: 0;
      left: 0;
      background-color: #000000 !important; /* Black*/
      overflow-x: hidden; /* Disable horizontal scroll */
      padding-top: 60px; /* Place content 60px from the top */
      transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
    }

    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
    .bm-item-list {
      color: #b8b7ad;
      margin-left: 10%;
      font-size: 20px;
      button {
        //  background-color: red;
        width: 100%;
        border: none;
        //  font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        background-color: #000000;
        color: #ffffff;
        transition: ease-in-out .5s;
        &:hover {
          background-color: #D33B2B;
          cursor: pointer;
        }
      }
      .router-link-exact-active {
        background-color: #D33B2B;
      }
    }
    .bm-item-list > * {
      display: flex;
      text-decoration: none;
      padding: 0.7em;
    }
    .bm-item-list > * > span {
      margin-left: 10px;
      font-weight: 700;
      color: white;
    }

@media screen and (max-width: 1040px) {
  #app {
    .top-header {
      .top-header-left {
        padding-right: 50px;
      }
    }
  }
}
@media screen and (max-width: 985px) {
  #app {
    .top-header {
      .top-header-left {
        padding-right: 0px;
      }
    }
  }
}
@media screen and (max-width: 940px) {
  #app {
    .top-header {
      display: none;
    }
    .nav-wrapper {
      display: none;
    }
    .burger-slider {
      display: block;
    }
  }
}
</style>
