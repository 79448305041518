<template>
  <div class="single-car-details" v-if="carDetails.loaded">
    <div class="car-title">{{ carDetails.data.naslov }}</div>
    <div class="info-wrapper">
      <div class="car-info">
        <div class="main-titles">
          <div class="price">Cijena:</div>
          <div class="price-amount">{{ carDetails.cijena.toLocaleString() }} KM</div>
        </div>
        <div class="main-titles">
          <div class="price">Godina:</div>
          <div class="price-amount">{{ carDetails.godina }}.</div>
        </div>
        <div class="main-titles">
          <div class="price">Kilometraža:</div>
          <div class="price-amount">{{ carDetails.kilometraza }}</div>
        </div>
         <div class="titles" v-if="carDetails.proizvodjac">
          <div class="title">Proizvođač:</div>
          <div class="value">{{ carDetails.proizvodjac }}</div>
        </div>
         <div class="titles" v-if="carDetails.model">
          <div class="title">Model:</div>
          <div class="value">{{ carDetails.model }}</div>
        </div>
        <div class="titles" v-if="carDetails.gorivo">
          <div class="title">Vrsta Motora:</div>
          <div class="value">{{ carDetails.gorivo }}</div>
        </div>
        <div class="titles" v-if="carDetails.konjskihSnaga">
          <div class="title">Konjskih snaga:</div>
          <div class="value">{{ carDetails.konjskihSnaga }}</div>
        </div>
        <div class="titles" v-if="carDetails.kilovata">
          <div class="title">Kilovata (KW):</div>
          <div class="value">{{ carDetails.kilovata }}</div>
        </div>
         <div class="titles" v-if="carDetails.kubikaza">
          <div class="title">Kubikaža:</div>
          <div class="value">{{ carDetails.kubikaza }}</div>
        </div>
        <div class="titles" v-if="carDetails.emisijskiStandard">
          <div class="title">Emisijski standard:</div>
          <div class="value">{{ carDetails.emisijskiStandard }}</div>
        </div>
        <div class="titles" v-if="carDetails.mjenjac">
          <div class="title">Mjenjač:</div>
          <div class="value">{{ carDetails.mjenjac }}</div>
        </div>
        <div class="titles" v-if="carDetails.stupnjeviPrijenosa">
          <div class="title">Stupnjevi prijenosa:</div>
          <div class="value">{{ carDetails.stupnjeviPrijenosa }}</div>
        </div>
        <div class="titles" v-if="carDetails.pogon">
          <div class="title">Pogon:</div>
          <div class="value">{{ carDetails.pogon }}</div>
        </div>
        <div class="titles" v-if="carDetails.bojaVozila">
          <div class="title">Boja vozila:</div>
          <div class="value">{{ carDetails.bojaVozila }}</div>
        </div>
        <div class="titles" v-if="carDetails.brojVrata">
          <div class="title">Broj vrata:</div>
          <div class="value">{{ carDetails.brojVrata }}</div>
        </div>
        <div class="titles" v-if="carDetails.velicinaTockova">
         <div class="title">Veličina točkova:</div>
         <div class="value">{{ carDetails.velicinaTockova }}</div>
        </div>
        <div class="titles" v-if="carDetails.visezonskaKlima">
         <div class="title">Višezonska klima:</div>
         <div class="value">{{ carDetails.visezonskaKlima }}</div>
        </div>
        <div class="titles" v-if="carDetails.zastitaVozila">
         <div class="title">Zaštita vozila:</div>
         <div class="value">{{ carDetails.zastitaVozila }}</div>
        </div>
        <div class="titles" v-if="carDetails.svjetla">
         <div class="title">Svjetla:</div>
         <div class="value">{{ carDetails.svjetla }}</div>
        </div>
        <div class="titles" v-if="carDetails.turbo">
          <div class="title">Turbo:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.dpf">
          <div class="title">DPF/FAP filter:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.metalik">
          <div class="title">Metalik:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.ocarinjen">
          <div class="title">Ocarinjen:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.servoVolan">
          <div class="title">Servo volan:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.tempomat">
          <div class="title">Tempomat:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.abs">
          <div class="title">ABS:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.esp">
          <div class="title">ESP:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.airbag">
          <div class="title">Zračni jastuci:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.elPodizaci">
          <div class="title">El. podizači stakala:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.klima">
          <div class="title">Klima:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.digitalnaKlima">
          <div class="title">Digitalna klima:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.centralnaBrava">
          <div class="title">Centralna brava:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.daljinskoOtkljucavanje">
          <div class="title">Daljinsko otključavanje:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
        <div class="titles" v-if="carDetails.autoKuka">
          <div class="title">Auto kuka:</div>
          <div class="value">
            <font-awesome-icon style="color: green" icon="check-square" size="1x"/>
          </div>
        </div>
      </div>
      <div class="car-gallery">
          <img class="image" v-for="(image, i) in carDetails.gallerySlide"
            :src="image"
            :key="i"
            @click="index = i">
          <vue-gallery-slideshow
            :images="carDetails.gallerySlide"
            :index="index"
            @close="index = null">
          </vue-gallery-slideshow >
      </div>
    </div>
  </div>
</template>

<script>
import { each } from 'lodash';
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'CarOffer',
  computed: {
  },
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      index: null,
      width: '100%',
      addons: {
        enableLargeView: true,
        enablePictureElement: true,
      },
      carDetails: {
        proizvodjac: null,
        model: null,
        naslov: null,
        cijena: null,
        godina: null,
        kilometraza: null,
        gorivo: null,
        mjenjac: null,
        bojaVozila: null,
        brojVrata: null,
        konjskihSnaga: null,
        kilovata: null,
        velicinaTockova: null,
        stupnjeviPrijenosa: null,
        kubikaza: null,
        pogon: null,
        emisijskiStandard: null,
        visezonskaKlima: null,
        zastitaVozila: null,
        svjetla: null,
        turbo: null,
        dpf: null,
        metalik: null,
        ocarinjen: null,
        servoVolan: null,
        tempomat: null,
        abs: null,
        esp: null,
        airbag: null,
        elPodizaci: null,
        klima: null,
        digitalnaKlima: null,
        centralnaBrava: null,
        daljinskoOtkljucavanje: null,
        autoKuka: null,
        galerijaSlika: [],
        gallerySlide: [],
        loaded: null,
      },
      carInfo: [],
    };
  },
  methods: {
  },
  mounted() {
    console.log('THIS CAR DATA: ', this.$route.params.carDetails);
    this.carDetails = this.$route.params.carDetails;
    this.carDetails.naslov = this.$route.params.carDetails.data.naslov;
    this.carDetails.cijena = this.$route.params.carDetails.data.cijena;
    const gallery = [];
    const gallerySlide = [];
    each(this.carDetails.data.polja, (item) => {
      if (item.naziv === 'Godi&scaron;te') {
        this.carDetails.godina = item.vrijednost;
      }
      if (item.naziv === 'Gorivo') {
        this.carDetails.gorivo = item.vrijednost;
      }
      if (item.naziv === 'Kilometraža') {
        this.carDetails.kilometraza = item.vrijednost;
      }
      if (item.naziv === 'Transmisija') {
        this.carDetails.mjenjac = item.vrijednost;
      }
      if (item.naziv === 'Boja') {
        this.carDetails.bojaVozila = item.vrijednost;
      }
      if (item.naziv === 'Broj vrata') {
        this.carDetails.brojVrata = item.vrijednost;
      }
      if (item.naziv === 'Konjskih snaga') {
        this.carDetails.konjskihSnaga = item.vrijednost;
      }
      if (item.naziv === 'Kilovata (KW)') {
        this.carDetails.kilovata = item.vrijednost;
      }
      if (item.naziv === 'Veličina felgi') {
        this.carDetails.velicinaTockova = item.vrijednost;
      }
      if (item.naziv === 'Broj stepeni prijenosa') {
        this.carDetails.stupnjeviPrijenosa = item.vrijednost;
      }
      if (item.naziv === 'Kubikaža') {
        this.carDetails.kubikaza = item.vrijednost;
      }
      if (item.naziv === 'Pogon') {
        this.carDetails.pogon = item.vrijednost;
      }
      if (item.naziv === 'Emisioni standard') {
        this.carDetails.emisijskiStandard = item.vrijednost;
      }
      if (item.naziv === 'Proizvodjac') {
        this.carDetails.proizvodjac = item.vrijednost;
      }
      if (item.naziv === 'Model') {
        this.carDetails.model = item.vrijednost;
      }
      if (item.naziv === 'Višezonska klima') {
        this.carDetails.visezonskaKlima = item.vrijednost;
      }
      if (item.naziv === 'Zaštita/Blokada') {
        this.carDetails.zastitaVozila = item.vrijednost;
      }
      if (item.naziv === 'Svjetla') {
        this.carDetails.svjetla = item.vrijednost;
      }
      if (item.naziv === 'Turbo') {
        this.carDetails.turbo = true;
      }
      if (item.naziv === 'DPF/FAP filter') {
        this.carDetails.dpf = true;
      }
      if (item.naziv === 'Metalik') {
        this.carDetails.metalik = true;
      }
      if (item.naziv === 'Ocarinjen') {
        this.carDetails.ocarinjen = true;
      }
      if (item.naziv === 'Servo volan') {
        this.carDetails.servoVolan = true;
      }
      if (item.naziv === 'Tempomat') {
        this.carDetails.tempomat = true;
      }
      if (item.naziv === 'ABS') {
        this.carDetails.abs = true;
      }
      if (item.naziv === 'ESP') {
        this.carDetails.esp = true;
      }
      if (item.naziv === 'Airbag') {
        this.carDetails.airbag = true;
      }
      if (item.naziv === 'El. podizači stakala') {
        this.carDetails.elPodizaci = true;
      }
      if (item.naziv === 'Klima') {
        this.carDetails.klima = true;
      }
      if (item.naziv === 'Digitalna klima') {
        this.carDetails.digitalnaKlima = true;
      }
      if (item.naziv === 'Centralna brava') {
        this.carDetails.centralnaBrava = true;
      }
      if (item.naziv === 'Daljinsko otključavanje') {
        this.carDetails.daljinskoOtkljucavanje = true;
      }
      if (item.naziv === 'Auto kuka') {
        this.carDetails.autoKuka = true;
      }
    });
    each(this.carDetails.data.slike, (slika) => {
      const singleImage = {
        id: 0,
        src: null,
        thumbnail: null,
        thumbnailWidth: '220px',
      };
      singleImage.src = slika.velika;
      singleImage.thumbnail = slika.thumb;
      gallery.push(singleImage);
      gallerySlide.push(singleImage.src);
      console.log('Slika:', slika.velika);
    });
    console.log('Galerija:', gallery);
    console.log('Galerija slide:', gallerySlide);
    this.carDetails.galerijaSlika = gallery;
    this.carDetails.gallerySlide = gallerySlide;
    this.carDetails.loaded = true;
  },
};
</script>

<style lang="scss">
::v-deep .lingalleryContainer {
  .lingallery {
    width: 100% !important;
  }
}
.single-car-details {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  .car-title {
    width: 600px;
    height: 70px;
    background-color: #000000;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 70px;
    margin-top: 20px;
    text-align: left;
    padding-left: 20px;
  }
  .info-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .car-info {
      display: flex;
      flex-direction: column;
      .main-titles {
        display: flex;
        margin-left: 20px;
        .price {
          text-align: right;
          height: 30px;
          width: 150px;
          line-height: 30px;
          background-color: #CD1D1F;
          font-family: 'Open Sans', sans-serif;
          font-size: 15px;
          color: #ffffff;
          font-weight: bold;
          margin-bottom: 5px;
          margin-top: 5px;
          padding: 7px;
        }
        .price-amount {
          text-align: right;
          height: 30px;
          width: 200px;
          line-height: 30px;
          background-color: #ffffff;
          font-family: 'Open Sans', sans-serif;
          font-size: 15px;
          color: #000000;
          font-weight: bold;
          margin-bottom: 5px;
          margin-top: 5px;
          padding: 7px;
        }
      }
      .titles {
        display: flex;
        margin-left: 20px;
        justify-content: center;
        .title {
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          max-width: 200px;
          width: 100%;
          text-align: right;
          padding-right: 5px;
        }
        .value {
          font-family: 'Open Sans', sans-serif;
          font-weight: bold;
          font-size: 14px;
          max-width: 200px;
          width: 100%;
          text-align: left;
        }
      }
    }
    .car-gallery {
      padding: 20px 5px 5px 20px;
      .image {
        width: 200px;
        cursor: pointer;
        padding: 5px;
      }
    }
  }
}
@media screen and (max-width: 940px) {

  .single-car-details {
    padding-top: 100px;
  }
}
@media screen and (max-width: 850px) {

  .single-car-details {
    padding-top: 100px;
   .info-wrapper {
     .car-gallery {
       .image {
         width: 150px;
         cursor: pointer;
         padding: 5px;
       }
     }
   }
  }
}
@media screen and (max-width: 740px) {
  .single-car-details {
   .info-wrapper {
     flex-direction: column;
     .car-gallery {
       .image {
         width: 150px;
         cursor: pointer;
         padding: 5px;
       }
     }
   }
  }
}
</style>
