import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    contactDetails: {
      icon: 'phone-square',
      title: 'KONTAKTIRAJTE NAS',
      infoLine1: 'Tel: +387 63 754 800',
      infoLine2: 'Tel: +387 63 754 777',
    },
    workingHours: {
      icon: 'clock',
      title: 'RADNO VRIJEME',
      infoLine1: 'Pon - Pet 09:00 - 17:00 sati',
      infoLine2: 'Sub 09:00 - 16:00 sati',
    },
    address: {
      icon: 'map-marker-alt',
      title: 'ADRESA',
      infoLine1: 'Buna b.b.',
      infoLine2: '88000 Mostar, BiH',
    },
    carOffer: null,
    carOfferTotal: null,
    carOfferTotalPages: null,
    totalArticles: null,
    mergedOffer: null,
  },
  mutations: {
    setCarOffer(state, payload) {
      state.carOffer = payload;
      state.totalArticles = payload.total;
    },
    setTotalArticles(state, payload) {
      state.totalArticles = payload;
      state.carOfferTotalPages = Number((payload / 25).toFixed());
    },
    setCarOfferTotal(state, payload) {
      state.carOfferTotal = payload;
    },
    setMergedOffer(state, payload) {
      state.mergedOffer = payload;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    contactDetails: (state) => state.contactDetails,
    workingHours: (state) => state.workingHours,
    address: (state) => state.address,
    getCarOffer: (state) => state.carOffer,
    getTotalArticles: (state) => state.totalArticles,
    getCarOfferTotalPages: (state) => state.carOfferTotal,
  },
});
