<template>
  <div class="home">
    <div class="mobile-logo">
      <img class="mobile-logo" src="../assets/DM-Logo.png">
    </div>
    <div class="home-title">
      Dobro došli na web stranice Auto salona DM Automobili!
    </div>
    <div class="company-image">
      <img src="../assets/DM-Company.jpg">
    </div>
    <div class="home-title left">
      Naše usluge
    </div>
    <div class="offer-wrapper">
      <div class="offer-container">
        <div class="icon-container">
          <font-awesome-icon icon="globe-americas" size="3x"/>
        </div>
        <div class="info-container">
          <div class="info-title">UVOZ</div>
          <div class="info-text">Sva rabljena vozila su kupljena i uvezena sa zapadnoeuropskog
            tržišta te posjeduju originalni certifikat o proizvođaču, te servisnu evidenciju.</div>
        </div>
      </div>
      <div class="offer-container">
        <div class="icon-container">
          <font-awesome-icon icon="pencil-alt" size="3x"/>
        </div>
        <div class="info-container">
          <div class="info-title">PO NARUDŽBI</div>
          <div class="info-text">Ukoliko niste pronašli vozilo za sebe na našoj trenutnoj ponudi,
            naručite vozilo. U najkraćem mogućem roku će vam biti ponuđeno rabljeno vozilo koje
            zadovoljava vaše zahtjeve, od boje, motora, godišta...</div>
        </div>
      </div>
      <div class="offer-container">
        <div class="icon-container">
          <font-awesome-icon icon="shield-alt" size="3x"/>
        </div>
        <div class="info-container">
          <div class="info-title">ZAŠTITA</div>
          <div class="info-text">Nudimo ugradnju kvalitetnih elektronskih zaštita, alarma kao
            i mehaničku zaštitu ZEDER.</div>
        </div>
      </div>
    </div>
    <div v-if="getCarOffer">
      <div class="home-title left">
      Najnovije u ponudi
    </div>
      <div class="latest-offer">
        <LatestCar :carData="getCarOffer.artikli[0]"></LatestCar>
        <LatestCar :carData="getCarOffer.artikli[1]"></LatestCar>
        <LatestCar :carData="getCarOffer.artikli[3]"></LatestCar>
        <LatestCar :carData="getCarOffer.artikli[4]"></LatestCar>
        <LatestCar :carData="getCarOffer.artikli[5]"></LatestCar>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import LatestCar from '../components/LatestCar.vue';

export default {
  name: 'Home',
  components: {
    LatestCar,
  },
  computed: {
    ...mapGetters([
      'getCarOffer',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.home {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  .mobile-logo {
    display: none;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    height: 25px;
  }
  .home-title {
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    margin-top: 30px;
    padding: 15px;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 10px 10px 0 0;
    &.left {
      text-align: left;
      padding-left: 20px;
    }
  }
  .company-image {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    img {
      width: 100%;
    }
  }
  .offer-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 20px;
    .offer-container {
      display: flex;
      max-width: 300px;
      width: 100%;
      padding: 10px;
      .icon-container {
        color: #ccc;
      }
      .info-container {
        //  display: flex;
        .info-title {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          text-align: left;
          padding-left: 15px;
        }
        .info-text {
          text-align: left;
          padding-left: 15px;
          font-family: 'Open Sans', sans-serif;
          font-size: 13px;
        }
      }
    }
  }
  .latest-offer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 940px) {
  .home {
    .mobile-logo {
      display: block;
    }
  }
}
</style>
