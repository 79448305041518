<template>
  <div class="info-box">
    <div class="icon-wrapper">
      <font-awesome-icon class="ikona" :icon="boxData.icon" size="3x"/>
    </div>
    <div class="info-wrapper">
      <div class="info-title">{{ boxData.title }}</div>
      <div class="info-text">{{ boxData.infoLine1 }}</div>
      <div class="info-text">{{ boxData.infoLine2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
  props: {
    boxData: {
      type: Object,
      default() {
        return {
          title: 'TITLE',
          infoLine1: 'Info line 1',
          infoLine2: 'Info line 2',
        };
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .info-box {
    display: flex;
    padding: 0px 20px;
    align-items: center;
    .icon-wrapper {
      color: #cccccc;
    }
    .info-wrapper {
      padding-left: 20px;
      .info-title {
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
      }
      .info-text {
        text-align: left;
        font-size: 13px;
        font-family: 'Open Sans', sans-serif;
        color: #555;
      }
    }
}
</style>
