<template>
  <div class="latest-car">
    <div class="image-wrapper">
      <img :src="carData.slike[0].default">
    </div>
    <div class="car-title">
      {{ carData.naslov }}
    </div>
    <div class="car-details">
      <div class="car-detail">
        CIJENA: {{ (carData.cijena).toLocaleString() }} KM
      </div>
      <div class="car-detail">
        KILOMETRAŽA: {{ carData.polja[0].vrijednost }}
      </div>
      <div class="car-detail">
        GODINA: {{ carData.polja[4].vrijednost }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LatestCar',
  props: {
    carData: {
      type: Object,
    },
  },
  data() {
    return {
      mainPicture: null,
    };
  },
  mounted() {
    this.mainPicture = this.carData.slike[0].default;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .latest-car {
    display: flex;
    flex-direction: column;
    margin: 5px;
    max-width: 220px;
    width: 100%;
    background-color: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 3px solid #EBEBEB;
    transition: ease-in-out .2s;
    &:hover {
      border-top: 3px solid #D33B2B;
      box-shadow: 0 0 10px #ccc;
      cursor: pointer;
    }
    .image-wrapper {
      width: 100%;
      height: 125px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .car-title {
      font-family: 'Montserrat', sans-serif;
      text-align: left;
      padding: 0px 5px;
      color: #333;
      height: 90px;
    }
    .car-details {
      height: 90px;
      border-top: 1px solid rgba(#999, .3);
      .car-detail {
        font-family: 'Open Sans', sans-serif;
        text-align: left;
        padding: 5px;
        font-size: 12px;
        color: #666;
      }
    }
  }
</style>
