import Vue from 'vue';
import VueResource from 'vue-resource';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import Lingallery from 'lingallery';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPhoneSquare,
  faClock,
  faMapMarkerAlt,
  faGlobeAmericas,
  faPencilAlt,
  faShieldAlt,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
library.add(faPhoneSquare,
  faClock,
  faMapMarkerAlt,
  faGlobeAmericas,
  faPencilAlt,
  faShieldAlt,
  faCheckSquare);
Vue.use(VueResource);
Vue.use(VueLodash, { name: 'custom', lodash });

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('lingallery', Lingallery);
Vue.component('VueGallerySlideshow', VueGallerySlideshow);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
